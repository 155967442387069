<template>
    <b-sidebar
      id="add-new-contract-sidebar"
      :visible="isAddNewCertificateSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-contract-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">Add New Certificate</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <!-- BODY -->
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @submit.prevent="submitCertificate"
        >

          <!-- Agreement Reference -->
          <b-form-group label="Job Number *" label-for="jobNumber">
            <b-form-input v-model="jobNumber" placeholder="Job Number" required />
          </b-form-group>

          <!-- Attachments (Single) -->
          <b-form-group label="Attachment" label-for="attachments">
            <b-form-file v-model="attachments" />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              block
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </template>
<script>
import {
    BFormGroup, BFormInput, BForm, BButton, BFormDatepicker, BFormFile, BSidebar,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"
import { getToken } from '@/auth/utils'
import { ref } from "@vue/composition-api"

const jobNumber = ref('')
const customer = ref('')
const description = ref('')
const issuedDate = ref(null)
const startDate = ref(null)
const endDate = ref(null)
const issuedBy = ref('')
const attachments = ref(null)
export default {
  components: {
    BFormFile,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormDatepicker,
    BSidebar,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewcontractSidebarActive',
    event: 'update:is-add-new-contract-sidebar-active',
  },
  props: {
    isAddNewCertificateSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async submitCertificate() {
        const userToken = getToken()
      const formData = new FormData()
      formData.append('JobNumber', this.jobNumber)
      formData.append('time', moment().format('YYYY-MM-DD'))
      formData.append('attachment', this.attachments)
      const headers = {
        'Content-Type': 'multipart/form-data',
        'X-Token-Access': `Bearer ${userToken}`,
      }
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/invoice_service/insertCertificate`,
          formData,
          { headers },
        )
        if (response.status === 200 && response.data.Status === 1) {
            this.$parent.getAllCertificates()
            this.$parent.isAddNewCertificateSidebarActive = false
            this.jobNumber = ""
            this.resetForm()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Certificate Created Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to Create Certificate',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error Submitting Certificate',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        console.log('Succeed')
      }
    },
    resetForm() {
      this.jobNumber = ''
      this.customer = ''
      this.description = ''
      this.issuedDate = null
      this.startDate = null
      this.endDate = null
      this.issuedBy = ''
      this.attachments = null
    },
    data() {
        return {
      jobNumber,
      customer,
      description,
      issuedDate,
      startDate,
      endDate,
      issuedBy,
      attachments,
    }
  },
},
}
</script>
