<template>
    <div>
  <div>
      <certificate-list-add-new
      :is-add-new-certificate-sidebar-active.sync="isAddNewCertificateSidebarActive"
    />
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">

          <b-row>
            <!-- Per Page -->
            <!-- Search -->
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-button
              variant="info"
              size="sm"
              title="Add New" @click="isAddNewCertificateSidebarActive=true"> <feather-icon icon="PlusIcon" /> Add New Certificate
              </b-button>
          </b-col>
          </b-row>
          </div>
        <div class="m-2">
          <b-row>

            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            ><vue-table-dynamic
        ref="refInvoicesListTable"
        :params="fetchInvoices">
             <template v-slot:column-0="{ props }">
             <span>{{ props.row }}</span>
             </template>
          <template v-slot:column-5="{ props }">
            <b-button @click="downloadAttachment(props.cellData[2])"
            variant="success"
            size="sm">
    Download
  </b-button> &nbsp;
            <b-button @click="downloadCertificate(props.cellData[7])"
            variant="info"
            size="sm">
    Download Certificate
  </b-button>
    </template>
        </vue-table-dynamic>
            </b-col>
          </b-row>
        </div>
          <!-- Table Top -->
      </b-card>
  </div>
      <!-- Table Container Card -->
    </div>
  </template>

  <script>
  import {
    BCard, BRow, BButton, BCol, BTable, BPagination, BButtonGroup, BTab, BTabs,
    BCardHeader, BCardBody, BModal, VBModal, BForm, BFormInput, BFormGroup, BFormDatepicker, BFormFile,
  } from 'bootstrap-vue'
  import store from '@/store'
  import VueJsonToCsv from 'vue-json-to-csv'
  import vSelect from 'vue-select'
  import moment from 'moment'
  import axios from 'axios'
  import { onUnmounted } from '@vue/composition-api'
  import DateRangePicker from 'vue2-daterange-picker'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import { getToken, getUserData } from '@/auth/utils'
  import Ripple from 'vue-ripple-directive'
  import useInvoicesList from './List'
  import CertificateListAddNew from './Add.vue'

  const userRole = ''
  const userFullName = getUserData().FullName
  const InvoiceId = ''
  const modalUploadShow = false
  const modalFileAttachments = false
  const uploadInvoiceId = ''
  const InvoiceDate = ''
  const InvoiceNumber = ''
  const InvoiceNumberOld = ''
  const modalShow = false
  const modalDetailShow = false
  const fileAttachments = []
  const messagesList = []
  const messageBox = ''
  const invoiceMessageId = ''
  const fetchInvoicesUnsliced = []
  const fetchInvoicesConfirmedUnsliced = []
  const fetchInvoicesUnconfirmedUnsliced = []
  const fetchInvoicesEmptyUnsliced = []
  const startDateRaw = new Date()
  const endDateRaw = new Date()
  const InvoiceReference = ''
  const InvoiceGrandTotal = ''
  const InvoiceTax = ''
  const currentCode = ''
  const InvoiceDPP = ''
  const isAddNewCertificateSidebarActive = false
  const attachmentModal = false
  const fetchCertificatesData = [['No', 'Job Number', 'Issued By', 'Issued At', 'Actions']]
  startDateRaw.setDate(startDateRaw.getDate() - 30)
  export default {
    components: {
      VueJsonToCsv,
      BFormDatepicker,
      BFormFile,
      BCard,
      BModal,
      BForm,
      BFormInput,
      BFormGroup,
      BButton,
      BButtonGroup,
      BRow,
      BCol,
      BCardHeader,
      BCardBody,
      DateRangePicker,
      CertificateListAddNew,
      BTable,
      BPagination,
      vSelect,
      BTabs,
      BTab,
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
    setup() {
      const BRANCH_APP_STORE_MODULE_NAME = 'app-branch'

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      })

      const statusOptions = [
        { label: 'Pending', value: 'pending' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ]

      const {
        perPageUnconfirmed,
        currentPageUnconfirmed,
        searchQueryUnconfirmed,
        fetchInvoicesUnconfirmed,
        totalInvoicesUnconfirmed,
        refInvoicesListTableUnconfirmed,
        perPageEmpty,
        currentPageEmpty,
        searchQueryEmpty,
        fetchInvoicesEmpty,
        totalInvoicesEmpty,
        refInvoicesListTableEmpty,
        fetchInvoices,
        fetchInvoicesConfirmed,
        tableColumns,
        perPage,
        perPageConfirmed,
        currentPage,
        currentPageConfirmed,
        totalInvoices,
        totalInvoicesConfirmed,
        dataMeta,
        perPageOptions,
        searchQuery,
        searchQueryConfirmed,
        sortBy,
        isSortDirDesc,
        refInvoicesListTableConfirmed,
        refetchDataAll,
        refetchDataAllInvoice,
        refetchDataConfirmedInvoice,
        refetchDataUnconfirmedInvoice,
        refetchDataEmptyInvoice,
        startDate,
        endDate,
        branchId,
        // UI
        resolveInvoicesRoleVariant,
        resolveInvoicesRoleIcon,
        resolveInvoicesStatusVariant,

      } = useInvoicesList()

      return {

        // Sidebar
        branchId,
        perPageConfirmed,
        currentPageConfirmed,
        searchQueryConfirmed,
        fetchInvoicesConfirmed,
        totalInvoicesConfirmed,
        refInvoicesListTableConfirmed,
        perPageUnconfirmed,
        currentPageUnconfirmed,
        searchQueryUnconfirmed,
        fetchInvoicesUnconfirmed,
        totalInvoicesUnconfirmed,
        refInvoicesListTableUnconfirmed,
        perPageEmpty,
        currentPageEmpty,
        searchQueryEmpty,
        fetchInvoicesEmpty,
        totalInvoicesEmpty,
        refInvoicesListTableEmpty,
        fetchInvoices,
        tableColumns,
        perPage,
        currentPage,
        totalInvoices,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchDataAll,
        refetchDataAllInvoice,
        refetchDataConfirmedInvoice,
        refetchDataUnconfirmedInvoice,
        refetchDataEmptyInvoice,
        startDate,
        endDate,
        // UI
        resolveInvoicesRoleVariant,
        resolveInvoicesRoleIcon,
        resolveInvoicesStatusVariant,
        statusOptions,
        InvoiceId,
        InvoiceDate,
        InvoiceNumber,
        InvoiceNumberOld,
      }
    },
      watch: {
          branchId: 'getAllCertificates',
          },
    data() {
      const perfectScrollbarSettings = {
        maxScrollbarLength: 150,
      }
        return {
            currentCode,
            attachmentModal,
            isAddNewCertificateSidebarActive,
          labels: {
                  Customer: { title: 'Customer' },
                  JobNo: { title: 'Job No' },
                  InvoiceNumber: { title: 'Invoice Number' },
                  InvDate: { title: 'Inv Date' },
                  Ref: { title: 'Ref' },
                  Currency: { title: 'Currency' },
                  DPP: { title: 'DPP' },
                  Tax: { title: 'Tax' },
                  GrandTotal: { title: 'Grand Total' },
                  InputPusatDate: { title: 'Input Pusat Date' },
                  Pusat_InvoiceDate: { title: 'Inv Date Pusat' },
                  Pusat_NoInvoice: { title: 'No Inv Pusat' },
                  Confirmed: { title: 'Confirmed' },
                  SupportingDoc: { title: 'Supporting Docs' },
          },
          fetchInvoices: {
            data: fetchCertificatesData,
            header: 'row',
            enableSearch: true,
            scrollbar: 'hidden',
            border: true,
            stripe: true,
            pagination: true,
            pageSize: 10,
            pageSizes: [10, 20, 30, 40, 50],
            showTotal: true,
            columnWidth: [{ column: 0, width: 50 } , { column: 1, width: 310 }, { column: 4, width: 100 }, { column: 3, width: 100 }, { column: 2, width: 200 }, { column: 6, width: 120 }, { column: 7, width: 90 }, { column: 8, width: 120 }, { column: 5, width: 360 }],
          },
          json_meta: [
            [
              {
                key: "charset",
                value: "utf-8",
              },
            ],
          ],
          modalFileAttachments,
          InvoiceReference,
          InvoiceGrandTotal,
          InvoiceTax,
          InvoiceDPP,
          fetchInvoicesUnsliced,
          fetchInvoicesConfirmedUnsliced,
          fetchInvoicesUnconfirmedUnsliced,
          fetchInvoicesEmptyUnsliced,
          userFullName,
          messagesList,
          invoiceMessageId,
          messageBox,
          perfectScrollbarSettings,
          branches: [],
          userRole,
          modalUploadShow,
          uploadInvoiceId,
          file: '',
          fileAttachments,
          modalShow,
          modalDetailShow,
          config: {
                wrap: true,
                dateFormat: 'Y-m-d',
            },
          pickerDates: {
              startDate: this.dataMeta.startDate ? this.dataMeta.startDate : startDateRaw,
              endDate: this.dataMeta.endDate ? this.dataMeta.endDate : endDateRaw,
          },
          locale: {
            direction: 'ltr',
            format: 'yyyy/mm/dd',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            firstDay: 1,
          },
        }
      },
      async mounted() {
        this.userRole = this.$cookies.get('UserRole')
      await this.getAllCertificates()
      },
    methods: {
      downloadCertificate(certId) {
        const url = `https://access.protegocloud.com/notary/certificate/${certId}`
        window.open(url)
      },
      downloadFile(url) {
            window.open(url)
        },
        onFileChange(event) {
      this.newFile = event.target.files
    },
    async uploadFiles() {
    const userToken = getToken()
      if (!this.newFile || this.newFile.length === 0) {
        // If no file is selected, do nothing
        return
      }

      const formData = new FormData()
      for (let i = 0; i < this.newFile.length; i += 1) {
        formData.append('attachments[]', this.newFile[i])
      }
      formData.append("code", this.currentCode)

      try {
        const response = await axios.post('https://api.geolims.com/invoice_service/addMoreCertificateAttachment', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Token-Access': `Bearer  ${userToken}`,
          },
        })

        if (response.status === 200) {
            this.showAttachments(this.currentCode)
          this.fileAttachments.push(...response.data.attachments)
          this.attachments = []
          this.attachments.length = 0
          this.newFile = null
        }
      } catch (error) {
        console.error('Error uploading files:', error)
      }
    },
        DeleteAttachment(id) {
            const userToken = getToken()
// Prepare headers
const headers_ = {
        'X-Token-Access': `Bearer ${userToken}`,
        'Content-Type': 'application/json',
    }
    const body = {
        id,
    }
    axios
        .post('https://api.geolims.com/invoice_service/deleteCertificateAttachment', body, { headers: headers_ })
        .then(response => {
            if (response.status === 200 && response.data.Status === 1) {
                this.showAttachments(this.currentCode)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Delete Attachment Succeed',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
        } else {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Error delete attachment',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        }
        })
        .catch(e => {
            console.log(e)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Error delete attachment',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
        },
        async getAllCertificates() {
    await this.emptyArray()

    this.userRole = this.$cookies.get('UserRole')

    const userToken = getToken()
    // Prepare headers
    const headers_ = {
        'X-Token-Access': `Bearer  ${userToken}`,
        'Content-Type': 'application/json',
    }

    axios
        .get('https://api.geolims.com/invoice_service/getCertificateList', { headers: headers_ })
        .then(response => {
            response.data.Certificates.map((certificate, index) => {
                const row = []
                row.push(index + 1)
                row.push(certificate[1])
                row.push(certificate[2])
                row.push(certificate[9])
                row.push(this.dateFormat(certificate[4], 'MM/DD/YYYY'))

                const actions = certificate
                row.push(actions)

                fetchCertificatesData.push(row)
            })
        })
        .catch(e => {
            console.log(e)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching certificate list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
},
      showDetail(reference, grandtotal, tax, dpp) {
        console.log(reference)
        console.log(grandtotal)
        console.log(tax)
        console.log(dpp)
        this.InvoiceReference = reference
        this.InvoiceGrandTotal = grandtotal
        this.InvoiceTax = tax
        this.InvoiceDPP = dpp
      },
      downloadInvoice(id) {
        const url = process.env.VUE_APP_PDF_SERVICE_URL
        const header = {
    'auth-username': process.env.VUE_APP_PDF_SERVICE_USERNAME,
    'auth-password': process.env.VUE_APP_PDF_SERVICE_PASSWORD,
  }
        const body = {
          id,
        }
      axios
        .post(url, body, { headers: header })
        .then(response => {
          window.open(response.data.data)
  })
      },
      async dateChange(val) {
        this.startDate = val.startDate
        this.endDate = val.endDate
        await this.getAllCertificates()
        await this.getAllCertificatesUnsliced()
      },
      searchChange({ type, target }) {
        console.log(type)
        this.searchQuery = target.value
        this.searchQueryConfirmed = target.value
        this.searchQueryUnconfirmed = target.value
      },
      dateFormat(value, format = 'MM/DD/YYYY hh:mm') {
          return moment(String(value)).format(format)
      },
      dateSimple(value) {
          let dateRet = ''
          if (value !== null) {
            dateRet = moment(String(value)).format('YYYY-MM-DD')
          } else { dateRet = null }
          return dateRet
      },
      numberFormat(value) {
        return parseFloat(value).toFixed(2)
      },
      editInvoice(invoiceID, invoiceDatePusat, invoiceNumberPusat, invoiceDate, invoiceNumber) {
        this.InvoiceID = invoiceID
        this.InvoiceDate = invoiceDatePusat !== null ? invoiceDatePusat : invoiceDate
        this.InvoiceNumber = invoiceNumberPusat !== null ? invoiceNumberPusat : ''
        this.InvoiceNumberOld = invoiceNumber
      },
      showAttachments(CertificateCode) {
        this.currentCode = CertificateCode
        const userToken = getToken()
        console.log('sending')
        const header = {
          'Content-Type': 'multipart/form-dataitem',
          'X-Token-Access': `Bearer ${userToken}`,
        }
      axios
        .get(`https://api.geolims.com/invoice_service/getCertificateAttachments/${CertificateCode}`, { headers: header })
        .then(response => {
          if (response.data.Status === 1) {
        fileAttachments.splice(0, fileAttachments.length)
        if (response.data.Attachments) {
          response.data.Attachments.map(value => {
            fileAttachments.push(value)
            return value.s3_url
          })
          this.attachmentModal = true
        }
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
      },
      async uploadAttachment() {
        const userToken = getToken()
        console.log('sending')
        const header = {
          'Content-Type': 'multipart/form-dataitem',
          'X-Token-Access': `Bearer ${userToken}`,
        }
        const formData = new FormData()
        formData.append('file', this.file[0], this.file[0].name)
        console.log(formData)
      axios
        .post(`https://api.geolims.com/invoice_service/upload-docs?invoiceid=${this.uploadInvoiceId}`, formData, { headers: header })
        .then(async response => {
          if (response.data.Status === 1) {
        await this.getAllCertificates()
            this.modalUploadShow = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Attachment success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      },
      async editInvoiceProcess() {
        const userToken = getToken()
        console.log('sending')
        const header = {
          'Content-Type': 'multipart/form-dataitem',
          'X-Token-Access': `Bearer ${userToken}`,
        }
        const body = {
          InvoiceID: this.InvoiceID,
          Date: this.InvoiceDate,
          NoInvoice: this.InvoiceNumber,
        }
      axios
        .post('https://api.geolims.com/invoice_service/saveInvoicePusat', body, { headers: header })
        .then(async response => {
        await this.getAllCertificates()
          if (response.data.Status === 1) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Edit Invoice success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      },
        handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
        },
       async confirmInvoice(invoiceID) {
        const userToken = getToken()
        console.log('sending')
        const header = {
          'Content-Type': 'multipart/form-dataitem',
          'X-Token-Access': `Bearer ${userToken}`,
        }
        const body = {
          InvoiceID: invoiceID,
        }
      axios
        .post('https://api.geolims.com/invoice_service/ApproveInvoice', body, { headers: header })
        .then(async response => {
          if (response.data.Status === 1) {
        await this.getAllCertificates()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Invoice success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
       },
       urlToBlob(url) {
  const userToken = getToken()
        const contentType = this.getBlobType(url)
        const header = {
          'X-Token-Access': `Bearer ${userToken}`,
          'Content-Type': contentType,
        }
            axios
              .get(url, { headers: header, responseType: 'blob' })
              .then(response => {
            const file = new Blob([response.data], { type: contentType })
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
              })
       },
       getBlobType(url) {
          const extension = url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase()
          let result = ''
          if (extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif' || extension === 'bmp') {
          result = `image/${extension}`
          } else if (extension === 'pdf') {
          result = `application/${extension}`
          } else if (extension === 'txt') {
          result = 'text/plain'
          }
          return result
       },
        getNum(val) {
          if (Number.isNaN(val)) {
            return 0
          }
          return val
        },
        async emptyArray() {
          const header = ['No', 'Job Number', 'Document', 'Issued By', 'Issued At', 'Actions']
          fetchCertificatesData.length = 0
          fetchCertificatesData.push(header)
        },
        async downloadAttachment(fileName) {
        const userToken = getToken()
        console.log('sending')
        const header = {
          'Content-Type': 'multipart/form-dataitem',
          'X-Token-Access': `Bearer ${userToken}`,
        }
          const url = `https://api.geolims.com/asset_service/saved-assets/`
          const folderName = `certificates/`
          const FileName = fileName.replace(url, '')
          const ObjectName = `${folderName}${FileName}`
          const bodyFormData = new FormData()
          bodyFormData.append('ObjectName', ObjectName)
          bodyFormData.append('FileName', FileName)
        axios
          .post(`https://api.geolims.com/asset_service/get_asset`, bodyFormData, { headers: header })
          .then(response => {
          if (response.data.Status === 1) {
            window.open(response.data.Payload, '_blank')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Download Attachment success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        },
    },
  }
  </script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
